import Header from '@/components/page/Header';
import LimitWidth from '@/containers/layout/LimitWidth';
import Link from 'next/dist/client/link';
import { useActiveMeals } from '@/utilities/meal';
import { CheckCircleIcon, CheckIcon, MailIcon } from '@heroicons/react/outline';
import SingleImage from '@/components/media/FillImage';
/* This example requires Tailwind CSS v2.0+ */

const features = [
    {
        name: 'Freshly Cooked Food',
        description:
            'We cook & deliver weekly to ensure freshness. Our food or ingredients are never frozen.',
    },
    {
        name: 'Tasty & Balanced Menu',
        description:
            'Our menu is designed by dietitians and chefs to get the right balance of tasty and healthy.',
    },
    {
        name: 'Simple Ordering System',
        description: 'Easily choose your meals and manage your subscription from your phone.',
    },
];
export default function Login(props) {
    const { meals } = useActiveMeals();

    return (
        <div className="">
            <Header />
            <div className="grid md:grid-cols-2">
                <div className="md:order-2 md:h-full">
                    <SingleImage
                        image={{
                            name: 'mainImage_U3oN4Kr7z.jpeg',
                            ratio: [3, 2],
                            maxWidth: '100vw',
                            className: 'w-full h-full z-2  ',
                        }}
                    />
                </div>

                <div className="relative bg-white py-12 px-7 pt-9 md:order-1 md:py-24">
                    <h4 className="text-dark leading text-center text-3xl font-bold leading-normal">
                        Homemade Meals <br />
                        Delivered Fresh
                        <br />
                        To Your Door
                    </h4>
                    <div className="mt-8 flex items-center space-x-4">
                        <Link href="/menu">
                            <a className="button-sm mx-auto w-1/2 text-center">Order Now</a>
                        </Link>
                    </div>
                </div>
            </div>

            <section className="overflow-hidden bg-gray-50 py-12 md:py-20 lg:py-24">
                <div className="relative mx-auto max-w-7xl px-7 sm:px-6 lg:px-8">
                    <svg
                        className="absolute top-full right-full translate-x-1/3 -translate-y-1/4 transform lg:translate-x-1/2 xl:-translate-y-1/2"
                        width={404}
                        height={404}
                        fill="none"
                        viewBox="0 0 404 404"
                        role="img"
                        aria-labelledby="svg-workcation"
                    >
                        <title id="svg-workcation">Workcation</title>
                        <defs>
                            <pattern
                                id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect
                                    x={0}
                                    y={0}
                                    width={4}
                                    height={4}
                                    className="text-gray-200"
                                    fill="currentColor"
                                />
                            </pattern>
                        </defs>
                        <rect
                            width={404}
                            height={404}
                            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
                        />
                    </svg>

                    <div className="relative">
                        <blockquote className="">
                            <div className="mx-auto max-w-lg text-center text-xl font-medium leading-9 text-gray-900">
                                <p>
                                    Fantastic service, great variety and high quality meals. Shows
                                    all the macros/calories and you can tailor it for what suits you
                                    best. Takes the pressure off having to cook and worry about
                                    healthy eating.
                                </p>
                            </div>
                            <footer className="mt-8">
                                <div className="md:flex md:items-center md:justify-center">
                                    <div className="md:flex-shrink-0"></div>
                                    <div className="mt-3   text-center md:mt-0 md:ml-4 md:flex md:flex-col md:items-center">
                                        <div className="mb-2 flex justify-center space-x-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
                                            </svg>
                                        </div>
                                        <div className="text-base font-medium text-gray-900">
                                            Conor Murphy
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </section>
            <div className="py-10 pb-14 text-center">
                <LimitWidth>
                    <h4 className="text-dark text-3xl font-bold leading-relaxed">Our Food</h4>
                    <p className="paragraph mb-6 mt-2 px-4">
                        Locally sourced, quality ingredients ensure every meal is tasty &
                        nutritious.
                    </p>
                    <div className="mt-8 flex items-center  justify-between ">
                        <h4 className="text-dark text-xl font-medium leading-relaxed">
                            Breakfast & Snacks
                        </h4>
                        <Link href="/menu">
                            <a className="font-semibold underline underline-offset-2"> Menu</a>
                        </Link>
                    </div>
                    <div className="max-w-screen overflow-x-scroll    " id="breakContainer">
                        <div className="mt-3 flex space-x-3">
                            {meals &&
                                meals
                                    .slice(0)
                                    .filter(
                                        (meal) => meal.type === 'snack' || meal.type === 'breakfast'
                                    )
                                    .map((meal, mi) => (
                                        <div key={mi} className="relative h-44 w-44 flex-none">
                                            <div className="z-10">
                                                <div className=" z-10   aspect-4/3">
                                                    <SingleImage
                                                        image={{
                                                            name: meal.images[0],
                                                            ratio: [1, 1],
                                                            maxWidth: '45vw',
                                                            className: 'w-full z-2 rounded ',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="absolute top-0 left-0 h-full w-full pb-0">
                                                <div className="max-h-20 w-full rounded-t-md  bg-gradient-to-b from-black/60 px-3 py-3 ">
                                                    <p className="max-h-10 overflow-hidden text-left  text-base font-semibold leading-5 text-white">
                                                        {meal.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </div>{' '}
                </LimitWidth>

                <LimitWidth>
                    <div className="mt-8 flex items-center  justify-between ">
                        <h4 className="text-dark text-xl font-medium leading-relaxed">
                            Main Meals
                        </h4>
                        <Link href="/menu">
                            <a className="font-semibold underline underline-offset-2"> Menu</a>
                        </Link>
                    </div>
                    <div className="max-w-screen  overflow-x-scroll " id="mainContainer">
                        <div className="mt-3 flex space-x-3">
                            {meals &&
                                meals
                                    .slice(0)
                                    .filter((meal) => meal.type === 'main')
                                    .map((meal, mi) => (
                                        <div key={mi} className="relative h-40 w-40 flex-none">
                                            <div className="z-10">
                                                <div className=" z-10   aspect-4/3">
                                                    <SingleImage
                                                        image={{
                                                            name: meal.images[0],
                                                            ratio: [1, 1],
                                                            maxWidth: '45vw',
                                                            className: 'w-full z-2 rounded ',
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="absolute top-0 left-0 h-full w-full pb-0">
                                                <div className="max-h-20 w-full rounded-t-md  bg-gradient-to-b from-black/50 px-3 py-3 ">
                                                    <p className="max-h-10 overflow-hidden text-left  text-base font-semibold leading-5 text-white">
                                                        {meal.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </div>{' '}
                </LimitWidth>
            </div>
            <div className="w-full bg-white pt-0 pb-14">
                <LimitWidth>
                    <h4 className="text-dark text-center text-3xl font-bold">How It Works</h4>
                    <p className="paragraph mb-10 mt-2 px-4 text-center md:mb-0">
                        It is easier than you think to have someone handle your nutrition.
                    </p>
                    <div className=" grid lg:grid-cols-3 lg:pb-9">
                        <div className="mt-6 flex items-start space-x-6 md:mt-10">
                            <div className="flex h-9 w-9 flex-none items-center justify-center rounded-full bg-gray-100 ">
                                <p className="text-lg font-bold text-gray-700">1</p>
                            </div>
                            <div className=" mt-1">
                                <h4 className="text-dark text-xl font-semibold ">Choose Meals</h4>
                                <p className="paragraph ml-13 mt-1 pr-4 ">
                                    Pick your favorite meals from our weekly rotating menu.
                                </p>
                            </div>
                        </div>

                        <div className="mt-10 flex items-start space-x-6">
                            <div className="flex h-9 w-9 flex-none items-center justify-center rounded-full bg-gray-100 ">
                                <p className="text-lg font-bold text-gray-700">2</p>
                            </div>
                            <div className=" mt-1">
                                <h4 className="text-dark text-xl font-semibold ">Keep It Cool</h4>
                                <p className="paragraph ml-13 mt-1 pr-4">
                                    Put your food in the fridge once it arrives to keep it fresh.
                                </p>
                            </div>
                        </div>

                        <div className="mt-10 flex items-start space-x-6">
                            <div className="flex h-9 w-9 flex-none items-center justify-center rounded-full bg-gray-100 ">
                                <p className="text-lg font-bold text-gray-700">3</p>
                            </div>
                            <div className=" mt-1">
                                <h4 className="text-dark text-xl font-semibold ">Heat And Eat</h4>
                                <p className="paragraph ml-13 mt-1 pr-4">
                                    Just pop your meal into the microwave and enjoy.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-12 flex items-center space-x-4">
                        <Link href="/menu">
                            <a className="button-sm mx-auto w-1/2 text-center">Order Now</a>
                        </Link>
                    </div>
                </LimitWidth>
            </div>
            <SingleImage
                image={{
                    name: 'footerImage_rV9yQXUmW.jpeg',
                    ratio: [3, 2],
                    maxWidth: '100vw',
                    className: 'w-full z-2  ',
                }}
            />
            <div className="bg-white py-12">
                <LimitWidth>
                    <h4 className="text-dark mb-3 text-center text-3xl font-bold ">
                        Why Parallel?
                    </h4>
                    <div className="bg-white">
                        <div className="mx-auto max-w-7xl py-8 md:py-24 lg:grid lg:gap-x-8 lg:py-16 lg:pt-10  ">
                            <dl className="grid space-y-10 sm:grid-flow-col sm:grid-cols-2  sm:gap-x-6 sm:gap-y-10 sm:space-y-0 md:grid-cols-3 md:gap-x-6">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative  md:text-center">
                                        <dt className="">
                                            <CheckIcon
                                                className="absolute h-6 w-6 text-green-500 md:hidden"
                                                aria-hidden="true"
                                            />
                                            <p className="ml-9 text-lg font-medium leading-6 text-gray-900 md:ml-0">
                                                {feature.name}
                                            </p>
                                        </dt>
                                        <dd className="mt-2 ml-9 text-base text-gray-500 md:ml-0 ">
                                            {feature.description}
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    <div className="mt-8 flex items-center space-x-4">
                        <Link href="/order">
                            <a className="button-sm mx-auto w-1/2 text-center">Order Now</a>
                        </Link>
                    </div>
                </LimitWidth>
            </div>
            <div className="flex w-full items-center justify-between px-7 py-6">
                <div className="mt-2 flex flex-row space-x-8 px-2 text-lg font-medium">
                    <a
                        href="mailto:help@parallelmeals.ie"
                        target="_blank"
                        className=""
                        rel="noreferrer"
                    >
                        <MailIcon className="h-5 w-5 text-gray-700" />
                    </a>
                    <a
                        href="https://www.instagram.com/parallelmeals/"
                        target="_blank"
                        className=""
                        rel="noreferrer"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-gray-700 "
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                        </svg>
                    </a>
                    <a
                        target="_blank"
                        href="https://www.facebook.com/parallelmeals/"
                        className=""
                        rel="noreferrer"
                    >
                        <svg
                            fill="#000000"
                            className="-mt-1 h-7 w-7 text-gray-700 "
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                        >
                            <path d="M 16 4 C 9.3844276 4 4 9.3844276 4 16 C 4 22.615572 9.3844276 28 16 28 C 22.615572 28 28 22.615572 28 16 C 28 9.3844276 22.615572 4 16 4 z M 16 6 C 21.534692 6 26 10.465308 26 16 C 26 21.027386 22.311682 25.161277 17.488281 25.878906 L 17.488281 18.916016 L 20.335938 18.916016 L 20.783203 16.023438 L 17.488281 16.023438 L 17.488281 14.443359 C 17.488281 13.242359 17.882859 12.175781 19.005859 12.175781 L 20.810547 12.175781 L 20.810547 9.6523438 C 20.493547 9.6093438 19.822688 9.515625 18.554688 9.515625 C 15.906688 9.515625 14.355469 10.913609 14.355469 14.099609 L 14.355469 16.023438 L 11.632812 16.023438 L 11.632812 18.916016 L 14.355469 18.916016 L 14.355469 25.853516 C 9.6088556 25.070647 6 20.973047 6 16 C 6 10.465308 10.465308 6 16 6 z" />
                        </svg>
                    </a>
                </div>
                <Link href={'/'}>
                    <a>
                        <img
                            className="h-4 w-auto"
                            alt=""
                            src="https://www.parallelnutrition.ie/wp-content/uploads/2018/05/new-website-copy-2.png"
                        />
                    </a>
                </Link>
            </div>
        </div>
    );
}
