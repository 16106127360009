import useSWR from 'swr';
import axios from 'axios';
import _ from 'underscore';
const fetcher = (url) => axios.get(url).then((res) => res.data);

export const useActiveMeals = () => {
    const { data, error } = useSWR(
        `${process.env.NEXT_PUBLIC_SERVER_URL}/api/meals/active`,
        fetcher
    );

    return {
        meals: data,
        isLoading: !error && !data,
        isError: error,
    };
};

export const useAllMeals = () => {
    const { data, error } = useSWR(`${process.env.NEXT_PUBLIC_SERVER_URL}/api/meals/`, fetcher);

    return {
        meals: data,
        isLoading: !error && !data,
        isError: error,
    };
};

export const useMeal = (id) => {
    const { data, error, mutate } = useSWR(
        `${process.env.NEXT_PUBLIC_SERVER_URL}/api/crud/meals/${id}`,
        fetcher
    );

    return {
        meal: data,
        isLoading: !error && !data,
        isError: error,
        mutateMeal: mutate,
    };
};

export const filterMeals = (meals, choices) => {
    const allergens = choices?.allergens?.value;
    const filteredMeals = [];
    if (!allergens && allergens?.length < 0) return meals;

    meals.forEach((meal) => {
        const mealHasAllergens = meal.allergens.filter((allergen) =>
            _.includes(allergens, allergen)
        );
        if (mealHasAllergens.length === 0) {
            const newSides = [];
            meal.sides.forEach((side) => {
                const sideHasAllergens = side.allergens.filter((allergen) =>
                    _.includes(allergens, allergen)
                );
                if (sideHasAllergens.length === 0) {
                    newSides.push(side);
                }
            });
            filteredMeals.push({ ...meal, sides: newSides });
        }
    });

    return filteredMeals;
};

export const sortMeals = (meals, choices) => {
    const sortedMeals = [];

    const sort = choices?.sort?.value;

    if (sort && sort?.macro && sort?.direction) {
        sortedMeals = _.sortBy(meals, (meal) => +meal.macros[sort.macro]);
        if (sort.direction === 'desc') sortedMeals.reverse();
    } else {
        sortedMeals = meals;
    }

    return sortedMeals;
};

export const getActiveMealIndex = (position, cartProducts) => {
    const indexedProducts = cartProducts.map((product, index) => ({ ...product, index: index }));

    let matchingProducts = indexedProducts.filter((product) => product.type === position.type);
    if (position.day)
        matchingProducts = matchingProducts.filter((product) => product.day === position.day);

    const matchingProduct = matchingProducts[+position.index];

    return matchingProduct.index;
};

export const getDefaultSide = (meal, choices) => {
    const goal = choices?.portion?.value?.index;
    const allergens = choices?.portion?.value;
    const sides = meal.sides;

    const caloriesExtractor = (side) => Number(side.macros.calories);
    const sortedSides = _.sortBy(sides, caloriesExtractor, 'asc');

    const selectedSide = undefined;

    switch (goal) {
        case 0:
            selectedSide = sides.length > 2 ? sortedSides[0] : sortedSides[0];
            break;
        case 1:
            selectedSide = sides.length > 2 ? sortedSides[1] : sortedSides[0];
            break;
        case 2:
            selectedSide = sides.length > 2 ? sortedSides[2] : sortedSides[1];
            break;
        default:
            selectedSide = sides.length > 2 ? sortedSides[1] : sortedSides[0];
            break;
    }

    return selectedSide;
};
