export default function LimitWidth(props) {
    const { children, size } = props;

    if (size === 'small') {
        return (
            <div className="mx-auto w-full px-7 pb-0 md:max-w-lg md:px-12 lg:max-w-xl lg:px-12">
                {children}
            </div>
        );
    }

    return <div className="mx-auto px-7 pb-0 md:px-12 lg:max-w-5xl lg:px-20">{children}</div>;
}
